.answers-list-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.answers-list-label {
  color: var(--primary-color);
  text-transform: capitalize;
}

.extra-info-config-switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
}
.extra-info-config-switch-label {
  font-size: 1rem;
  color: var(--text-color);
}
