.payment-account-connect-container {
  border-top: 2px solid #d8d8d8;
  width: 97%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
}
.connected-account-container {
  margin: 0 auto;
}

.payzen-connect-btn {
  border-radius: 5px;
  border: none;
  padding: 8px 1.5rem;
  cursor: pointer;
}
.payment-platforms-container {
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 100%;
  justify-content: center;
}
.payzen-account-config-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.stripe-connect-btn {
  border-radius: 5px;
  border: none;
  padding: 8px 1.5rem;
  cursor: pointer;
  background-color: #6772e5;
}

.payzen-account-submit {
  height: min-content;
  width: max-content;
  margin-top: 2rem !important;
}

.payment-dashboard-link {
  margin: 30px auto 0;
  padding: 1rem 2rem;
  border: 2px solid #6772e5;
  background-color: #6772e5;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: fit-content;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  width: fit-content;
}

.payment-dashboard-link:hover {
  background-color: #7795f8;
  color: white;
}
