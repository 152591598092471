.booking-card {
  height: fit-content;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.booking-header {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.booking-header p {
  margin: 0;
  color: #008670;
  font-size: 16px;
  font-weight: 500;
}

.booking-time {
  margin: 0;
  color: #008670;
  font-size: 16px;
  font-weight: 500;
}

.booking-item {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 5px 10px;
}

.booking-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 12px;
}

.booking-item-details {
  padding-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: "space-between" 
}

.booking-item-details p {
  margin: 0;
}

.chip-label {
  display: flex;
  gap: 10px;
  color: #33353b;
}

.booking-info {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.person-count {
  color: #53a295;
}

.contact-info {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.contact-info p {
  margin: 0;
}

.contact-detail {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  color: #657388;
}

.contact-detail p {
  margin: 0
}

.dot-separator {
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.booking-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.booking-summary p {
  margin: 0;
  color: #656e88;
}

.total-price {
  margin: 0;
  color: #252931;
  font-weight: 500;
}

.payment-method {
  color: #6ab2a6;
}

.extra-info {
  width: 100%;
}

.extra-info p {
  margin: 0;
}

.pricing-container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 12px;
}

.price-badge {
  border: 1px solid #008670;
  border-radius: 50px;
  padding: 6px 12px;
}

.price-badge p {
  color: #008670;
  margin: 0;
}

.see-less-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 12px;
  color: #008670;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.booking-staff {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.booking-staff p {
  text-transform: uppercase;
  color: #2CA58D;
  font-weight: 700;
}