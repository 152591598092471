.price-type-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.tiered-prices-container {
  display: flex;
  flex-direction: column;
}

.tiered-prices-activation-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.price-activation-switch-label {
  color: var(--primary-color);
}
.tier-label {
  font-weight: 700;
  width: max-content;
}

.tier-container {
  display: grid;
  gap: 10px;
  grid-template-columns: max-content repeat(3, 1fr);
  align-items: center;
}

.add-tier-container {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: self-start;
  color: var(--primary-color);
  font-weight: 600;
}
