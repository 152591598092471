.page-emailing {
  display: flex;
  background-color: #fff;
}

.container-emailing {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  overflow-y: auto;
  padding: 20px;
}

.container-card {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  width: 100%;
}

.row-card {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.card {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #b2b2b2;
  padding: 20px;
  position: relative;
}

.card-section {
  width: 50%;
}

.title {
  color: #2ca58d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.list-chip {
  margin-bottom: 40px;
  display: flex;
  flex-flow: wrap;
  gap: 20px;
}

.chip {
  border-radius: 30px;
  border: 1px solid #2ca58d;
  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(255, 255, 255, 0.3);
}

.chip-active {
  border-radius: 30px;
  border: 1px solid #2ca58d;
  box-shadow:
    0px 6px 12px rgba(0, 0, 0, 0.15),
    0px 3px 6px -3px rgba(255, 255, 255, 0.3);
}

.attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  margin-bottom: 50px;
}

.btn-right {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  background-color: #dcf8f1;
  width: 96px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

.note {
  font-size: 14px;
  color: #666;
  text-align: left;
}

.desc {
  font-size: 12px;
  color: #009688;
  margin: 8px;
}

.space-switch {
  display: flex;
  justify-content: space-around;
}

.switch {
  display: flex;
  align-items: center;
  gap: 5px;
}
