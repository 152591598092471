.page-alert {
  display: flex;
  height: 100%;
  position: relative;
}

.page-alert .container {
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
}

.form-alert {
  width: 100%;
}

.page-alert .wrapper-button {
  margin-top: 3rem;
  text-align: center;
  position: sticky;
  bottom: 2rem;
}

.page-alert .wrapper-button button {
  text-transform: uppercase;
  font-weight: bold;
}

.table-alert {
  table-layout: auto;
  border: 1px solid #d9d9d9;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;
  width: 100%;
}
.table-alert thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
  background-color: #f9f9f9;
}
.table-alert tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table-alert th,
.table-alert td {
  padding: 5px 1rem 6px;
  border-left: 1px solid #d9d9d9;
  vertical-align: middle;
}
.table-alert th {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #004643;
}
.table-alert td {
  border-top: 1px solid #d9d9d9;
}
.table-alert thead:first-child tr:first-child th:first-child,
.table-alert tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
.table-alert thead:last-child tr:last-child th:first-child,
.table-alert tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
.table-alert .title {
  font-size: 18px;
  color: var(--text-color);
}
.table-alert .label {
  font-size: 14px;
  color: "#004643";
  font-weight: 600;
  margin: 0;
}
