.modal-subtitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 10px;
  color: var(--text-color);
}
.form-control {
  display: inline-block;
  width: 100%;
}
.slot-capacity-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 4px;
  width: max-content;
}

.slot-capacity-input-active {
  padding: 3px 8px;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  background-color: var(--background-color-1);
  color: var(--text-color);
  font-size: 1.25rem;
  width: 4rem;
}
.slot-capacity-input-disabled {
  border: none;
  outline: none;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-color);
  background-color: inherit;
  width: 4rem;
}
.icon-container {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 0 2px;
  border-radius: 3px;
  cursor: pointer;
}
